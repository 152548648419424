<template>
  <div id="tile">
    <baidu-map class="bm-view" id="bm-div" :center="centerPoint" :zoom="zoom" :scroll-wheel-zoom="true" @click="get_point">
      <bm-tile
          :isTransparentPng="true"
        tileUrlTemplate="http://www.yz-awen.cn:9090/files/roadmap/{Z}/{X}/{Y}.jpg">
<!--        tileUrlTemplate="http://www.yz-awen.cn:9090/files/tiles/{Z}/tile-{X}_{Y}.png"v-if="isWaPian">-->

<!--        "https://www.yz-exam.cn:8081/assets/tiles/{Z}/tile-{X}_{Y}.png"-->
      </bm-tile>

    </baidu-map>
  </div>
</template>

<script>

// tileUrlTemplate="tiles/{Z}/tile-{X}_{Y}.png"
// 中点坐标: 109.452338,24.335764

export default {
  name: 'tile',

    data(){
      return{
          centerPoint:{lng: 108.652338, lat: 24.485764},
          zoom:16,
          clientHeight: 0,
          locPostion:{
              width:10,
              height:10
          },
          start:"",
          end:"",
          isNav:false,
          isWaPian:true,
          isShowPoint:false,
          showPonit:{
              lng:0,
              lat:0
          },
          showPonitName:"",
          toolTipDisable:false,
          QQqunModol:false,
          help:false,
          infoWindow: {
              show: true,
              contents: '9月12号'
          },
          tieba:{lat: 24.332834, lng: 109.455298}
      }
    },
    mounted(){
      this.clientHeight =  `${document.documentElement.clientHeight}`;
        window.onresize = function temp() {
            this.clientHeight = `${document.documentElement.clientHeight}`;
        };
        this.colseToolTips();
    },
    watch:{
        clientHeight: function () {
            this.changeFixed(this.clientHeight)
        }
    },
    methods: {
        changeFixed(clientHeight) {                        //动态修改样式
            document.getElementById("bm-div").style.height = clientHeight + 'px';
        },
    }
}
</script>

<style>



</style>


